import React, { useState } from "react";
import { Col, Ratio } from "react-bootstrap";
import Styles from "./EventItem.module.css";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { getFormatedDate } from "../../../utils/Utils";

const EventItem = ({ data }) => {
  const { width } = useWindowDimensions();
  const [inView, setInView] = useState(false);

  const handleViewportEnter = () => {
    setInView(true);
  };

  const handleViewportLeave = () => {
    setInView(false);
  };

  return (
    <>
      {width > 576 ? (
        <Link to={`/events/${data.id}`} className={`${Styles.item}`}>
          <Ratio aspectRatio="1x1">
            <img src={data.mainPhoto} className="w-100" />
          </Ratio>
          <p className={Styles.title}>{data.title}</p>
          {data.createdAt && <p className={Styles.date}>{getFormatedDate(data.createdAt, "YYYY.MM.DD")}</p>}
          <p className={Styles.description}>{data.description.replace(/<[^>]*>?/gm, "")}</p>
        </Link>
      ) : (
        <Link to={`/events/${data.id}`} className={`${Styles.item}`}>
          <motion.div
            initial={{ scale: 1, marginTop: "15px" }}
            whileInView={{ scale: 1.1, marginTop: 0 }}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            onViewportEnter={handleViewportEnter}
            onViewportLeave={handleViewportLeave}
          >
            <Ratio aspectRatio="1x1">
              <img src={data.mainPhoto} className="w-100" />
            </Ratio>
            <div className={`${Styles.textContent} ${inView ? Styles.showTextContent : ""}`}>
              <div className={Styles.titleDate}>
                <p className={Styles.title}>{data.title}</p>
                {data.createdAt && (
                  <p className={Styles.date}>{getFormatedDate(data.createdAt, "YYYY.MM.DD")}</p>
                )}
              </div>
              <p className={Styles.description}>{data.description.replace(/<[^>]*>?/gm, "")}</p>
            </div>
          </motion.div>
        </Link>
      )}
    </>
  );
};

export default EventItem;
